import React from "react"
import Layout from '../components/Layout';
import SearchComponent from "../components/SearchComponent";

const SearchPage = () => {
  return (
  <Layout>
    <section>
      <div> I am still working on the search. In the mean time you can use Google search.</div>
      <SearchComponent></SearchComponent>
    </section>  
  </Layout>
  )
}

export default SearchPage

